* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}


html {
  font-size: calc(1.0 * 62.5%);
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

form {
  display: flex;
  margin: 20px auto;
  gap: 10px;
  width: 30%;
  align-items: center;
  flex-direction: column;
}

label {
  font-size: 1.5rem;
}

input {
  width: 100%;
  height: 30px;
}
